import * as React from 'react';

function SvgFileEarmarkPersonFill(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      className="file-earmark-person-fill_svg__bi file-earmark-person-fill_svg__bi-file-earmark-person-fill"
      viewBox="0 0 16 16"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path d="M9.293 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4.707A1 1 0 0013.707 4L10 .293A1 1 0 009.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 01-1-1zM11 8a3 3 0 11-6 0 3 3 0 016 0zm2 5.755V14a1 1 0 01-1 1H4a1 1 0 01-1-1v-.245S4 12 8 12s5 1.755 5 1.755z" />
    </svg>
  );
}

export default SvgFileEarmarkPersonFill;
