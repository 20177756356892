import * as React from 'react';

function SvgKanban(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      className="kanban_svg__bi kanban_svg__bi-kanban"
      viewBox="0 0 16 16"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path d="M13.5 1a1 1 0 011 1v12a1 1 0 01-1 1h-11a1 1 0 01-1-1V2a1 1 0 011-1h11zm-11-1a2 2 0 00-2 2v12a2 2 0 002 2h11a2 2 0 002-2V2a2 2 0 00-2-2h-11z" />
      <path d="M6.5 3a1 1 0 011-1h1a1 1 0 011 1v3a1 1 0 01-1 1h-1a1 1 0 01-1-1V3zm-4 0a1 1 0 011-1h1a1 1 0 011 1v7a1 1 0 01-1 1h-1a1 1 0 01-1-1V3zm8 0a1 1 0 011-1h1a1 1 0 011 1v10a1 1 0 01-1 1h-1a1 1 0 01-1-1V3z" />
    </svg>
  );
}

export default SvgKanban;
